import React from 'react';

const SizePreview = ({ selectedSize, onSelectSize }) => {
  const sizes = [
    { name: 'A0', width: 841, height: 1189 },
    { name: 'A1', width: 594, height: 841 },
    { name: 'A2', width: 420, height: 594 },
    { name: 'A3', width: 297, height: 420 },
    { name: 'A4', width: 210, height: 297 },
    { name: 'A5', width: 148, height: 210 },
    { name: 'A6', width: 105, height: 148 },
    { name: 'A7', width: 74, height: 105 },
  ];

  const maxWidth = 841; // A0 width
  const maxHeight = 1189; // A0 height
  const scale = 0.5; // Adjust this to fit your layout

  return (
    <div className="size-preview">
      <svg width={maxWidth * scale} height={maxHeight * scale} viewBox={`0 0 ${maxWidth} ${maxHeight}`}>
        {sizes.map((size) => (
          <g key={size.name}>
            <rect
              x={maxWidth - size.width}
              y={0}
              width={size.width}
              height={size.height}
              fill={selectedSize === size.name ? '#e0e0e0' : 'white'}
              stroke="black"
              strokeWidth="2"
              onClick={() => onSelectSize(size.name)}
              style={{ cursor: 'pointer' }}
            />
            <text
              x={maxWidth - size.width / 2}
              y={size.height / 2}
              textAnchor="middle"
              dominantBaseline="middle"
              fontSize="20"
            >
              {size.name}
            </text>
            <text
              x={maxWidth - size.width / 2}
              y={size.height / 2 + 30}
              textAnchor="middle"
              dominantBaseline="middle"
              fontSize="12"
            >
              {`${size.width} x ${size.height}mm`}
            </text>
          </g>
        ))}
      </svg>
    </div>
  );
};

export default SizePreview;